
import { useFeatureFlag } from 'configcat-react';
import React, { useContext, useEffect, useState } from 'react';
import { CONFIGCAT_KEYS } from '../configCat/configCatKeys';
import { DeathBenefitName } from '../configCat/interfaces';
import { useTracking } from '../Tracking/TrackingContext';

interface ABtestingContextProviderProps {
    children: React.ReactNode | React.ReactFragment | JSX.Element;
}

const deathBenefitName: DeathBenefitName = {
    variant: '',
    name: ''
}

export const ABtestingContext = React.createContext({
    deathBenefitNameJson: deathBenefitName
});

const ABtestingProvider = (props: ABtestingContextProviderProps) => {

    const { configCatUserDetails } = useTracking();
    const { value: deathBenefitNameVariant, loading: deathBenefitNameVariantLoading } = useFeatureFlag(CONFIGCAT_KEYS.DEATH_BENEFIT_NAME_VARIANT.key, CONFIGCAT_KEYS.DEATH_BENEFIT_NAME_VARIANT.defaultValue.name, configCatUserDetails);

    const [deathBenefitNameJson, setDeathBenefitNameJson] = useState<DeathBenefitName>({
        variant: "",
        name: ""
    });

    useEffect(() => {
        if (!deathBenefitNameVariantLoading) {
            try {
                const deathBenefitNameJson = JSON.parse(deathBenefitNameVariant) as DeathBenefitName;
                setDeathBenefitNameJson(deathBenefitNameJson);
            } catch (e) {
                setDeathBenefitNameJson({
                    variant: 'a',
                    name: 'Death Benefit'
                });
            }
        }
    }, [deathBenefitNameVariant, deathBenefitNameVariantLoading])

    const values = {
        deathBenefitNameJson
    };


    return (
        <>
            <ABtestingContext.Provider value={values}>{props.children}</ABtestingContext.Provider>
        </>
    );
};

export const useABtesting = () => {
    return useContext(ABtestingContext);
};

export default ABtestingProvider;
