import { Avatar, Box, Link, Stack, Typography, styled } from '@mui/material';
import { useState } from 'react';
import CloseIcon from '../../assets/img/Close.png';
import InfoIcon from '../../assets/img/info-icon.png';
import { GetInstallmentLoanQuotationResponse, GetShortTermLoanQuotationResponse } from '../../clients/LoanClient';
import { getDeathBenefitVariantText } from '../../configCat/helpers/DeathBenefitHelper';
import { useABtesting } from '../../contexts/ABtestingContext';
import { useAppSettings } from '../../contexts/AppSettingsContext';
import { theme } from '../../theme/Theme';
import { formatMoney } from '../../utils/Helpers/SliderHelperFunctions';

const FeeHeading = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1.6rem 0 0.8rem 0',
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
}));

const FeeRowItem = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0.2rem 0 0.7rem 0',
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
}));

interface QuotationFeeBreakdownPopupProps {
  open: boolean;
  handleClose: () => void;
  deathBenefitOptedIn: boolean;
  getQuotationResponse: GetInstallmentLoanQuotationResponse | GetShortTermLoanQuotationResponse | undefined;
}

const QuotationFeeBreakdownPopup: React.FunctionComponent<QuotationFeeBreakdownPopupProps> = ({
  open,
  handleClose,
  deathBenefitOptedIn,
  getQuotationResponse,
}: QuotationFeeBreakdownPopupProps) => {
  const [showCreditLife, setShowCreditLife] = useState<boolean>(false);
  const [showMore, setShowMore] = useState<boolean>(false);
  const { insurance } = useAppSettings();
  const { deathBenefitNameJson } = useABtesting();

  return (
    <Box
      display={open ? 'block' : 'none'}
      position={'absolute'}
      sx={{
        top: { xs: '9rem', md: showMore ? '9rem' : '23rem' },
        left: { xs: '1.2rem', md: 0 },
        right: { xs: 0, md: 0 },
        width: { xs: 'calc(100vw - 2.4rem)', sm: '35.7rem' },
        borderRadius: '0.5rem',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        transition: 'all 0.25s ease-in-out',
        zIndex: '1',
        marginLeft: { xs: 'unset', md: 'auto' },
        marginRight: { xs: 'unset', md: 'auto' },
      }}
    >
      {
        showCreditLife ?
          <>
            <Stack
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              flexGrow={1}
              sx={{
                padding: '0.4rem 1.3rem',
                backgroundColor: theme.palette.secondary.main,
                borderTopLeftRadius: '0.5rem',
                borderTopRightRadius: '0.5rem',
              }}
            >
              <Typography
                fontSize={'1.8rem'}
                fontWeight={500}
                lineHeight={'4rem'}
                color={theme.palette.common.white}
                fontStyle={'normal'}
              >
                What is Credit Life Insurance?
              </Typography>
              <Avatar
                src={CloseIcon}
                alt={'Close Icon'}
                sx={{ width: '2.5rem', height: '3.2rem', cursor: 'pointer' }}
                onClick={() => {
                  handleClose();
                  setShowCreditLife(false);
                  setShowMore(false);
                }}
              />
            </Stack>

            <Box sx={{ backgroundColor: theme.palette.common.white, padding: '1.6rem', borderRadius: '0.5rem' }}>
              <Typography fontSize={'1.3rem'} fontWeight={300} lineHeight={'1.5rem'} fontStyle={'normal'}>
                In terms of the National Credit Act, we may mandate that a credit life policy which covers the outstanding debt in the event of your death, retrenchment and permanent or temporary disability will be in place with this loan.
                As a service to all of our valued clients, we offer you a Credit Life policy, payable by yourself.
                Please note you also have this freedom of choice to make available an existing policy that you already have in place, or to enter into the new Credit Life policy offered to you.
                If you have an existing policy in place, please contact us.
              </Typography>
              <br />
              <Typography fontSize={'1.3rem'} fontWeight={300} lineHeight={'1.5rem'} fontStyle={'normal'}>
                If you already have or would like to use a different Credit Life provider, please contact our call centre at{' '}
                <Link
                  href={`tel:${insurance.insuranceContactNumber.replace(/\s/g, '')}`}
                  sx={{ textDecoration: 'none' }}
                >
                  {insurance.insuranceContactNumber}
                </Link>
                {' '}or send an email to{' '}
                <Link href={`mailto:${insurance.insuranceContactEmail}`} sx={{ textDecoration: 'underline' }}>
                  {insurance.insuranceContactEmail}
                </Link>
                {' '}and kindly provide us with the relevant details.
              </Typography>
              <br />
              {!showMore ? (
                <Typography
                  fontSize={'1.3rem'}
                  fontWeight={400}
                  lineHeight={'1.5rem'}
                  fontStyle={'normal'}
                  color={theme.palette.primary.main}
                  onClick={() => setShowMore(true)}
                  sx={{ cursor: 'pointer' }}
                >
                  Read more
                </Typography>
              ) : (
                <>
                  <Typography fontSize={'1.3rem'} fontWeight={300} lineHeight={'1.5rem'} fontStyle={'normal'}>
                    Wonga is a registered Juristic Representative of Groups Are Us Pty (Ltd) (An Authorized Financial Services Provider, Registration Number: 2020/553498/07, FSP No. 45735).
                    The Death Benefit and Credit Life is underwritten by Sanlam Developing Markets Limited, an authorised financial services provider and licensed Insurer (Registration number 1911/003818/06, (FSP11230).
                    <br />
                    <br />
                    <Typography
                      component={'span'}
                      fontSize={'1.3rem'}
                      fontWeight={300}
                      lineHeight={'1.5rem'}
                      fontStyle={'normal'}
                      color={theme.palette.primary.main}
                      onClick={() => setShowMore(false)}
                      sx={{ cursor: 'pointer' }}
                    >
                      Read less
                    </Typography>
                  </Typography>
                </>
              )}
            </Box>
          </>
          :
          <>
            <Stack
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              flexGrow={1}
              sx={{
                padding: '0.4rem 1.3rem',
                backgroundColor: theme.palette.secondary.main,
                borderTopLeftRadius: '0.5rem',
                borderTopRightRadius: '0.5rem',
              }}
            >
              <Typography
                fontSize={'1.8rem'}
                fontWeight={500}
                lineHeight={'4rem'}
                color={theme.palette.common.white}
                fontStyle={'normal'}
              >
                Fees Breakdown
              </Typography>
              <Avatar
                src={CloseIcon}
                alt={'Close Icon'}
                sx={{ width: '2.5rem', height: '3.2rem', cursor: 'pointer' }}
                onClick={handleClose}
              />
            </Stack>

            <Box
              sx={{
                backgroundColor: theme.palette.common.white,
                padding: '0 1.5rem',
                borderBottomLeftRadius: '0.5rem',
                borderBottomRightRadius: '0.5rem',
              }}
            >
              <FeeHeading>
                <Typography fontSize={'1.4rem'} fontWeight={700} lineHeight={'2.8rem'} color={theme.palette.primary.main}>
                  Loan fees & interest
                </Typography>
              </FeeHeading>

              <FeeRowItem>
                <Typography fontSize={'1.4rem'} fontWeight={400} lineHeight={'2.8rem'}>
                  Initiation fee
                </Typography>
                <Typography fontSize={'1.4rem'} fontWeight={700} lineHeight={'2.8rem'}>
                  R {formatMoney(getQuotationResponse?.initiationFee ?? 0)}
                </Typography>
              </FeeRowItem>
              <FeeRowItem>
                <Typography fontSize={'1.4rem'} fontWeight={400} lineHeight={'2.8rem'}>
                  Service fees
                </Typography>
                <Typography fontSize={'1.4rem'} fontWeight={700} lineHeight={'2.8rem'}>
                  R {formatMoney(getQuotationResponse?.serviceFee ?? 0)}
                </Typography>
              </FeeRowItem>
              <FeeRowItem>
                <Typography fontSize={'1.4rem'} fontWeight={400} lineHeight={'2.8rem'}>
                  Total interest
                </Typography>
                <Typography fontSize={'1.4rem'} fontWeight={700} lineHeight={'2.8rem'}>
                  R {formatMoney(getQuotationResponse?.interestAmount ?? 0)}
                </Typography>
              </FeeRowItem>

              <FeeHeading>
                <Typography fontSize={'1.4rem'} fontWeight={700} lineHeight={'2.8rem'} color={theme.palette.primary.main}>
                  Insurance
                </Typography>
              </FeeHeading>
              {deathBenefitOptedIn && (
                <FeeRowItem>
                  <Typography fontSize={'1.4rem'} fontWeight={400} lineHeight={'2.8rem'}>
                    {getDeathBenefitVariantText(deathBenefitNameJson.name, true)}
                  </Typography>
                  <Typography fontSize={'1.4rem'} fontWeight={700} lineHeight={'2.8rem'}>
                    {`R ${formatMoney(getQuotationResponse?.deathBenefitContractValue ?? 0)}`}
                  </Typography>
                </FeeRowItem>
              )}
              <FeeRowItem>
                <Stack direction={'row'} alignItems={'center'}>
                  <Typography component={'span'} fontSize={'1.4rem'} fontWeight={400} lineHeight={'2.8rem'}>
                    Credit Life
                  </Typography>
                  <Avatar
                    src={InfoIcon}
                    alt="Information icon"
                    sx={{
                      width: '1.6rem',
                      height: '1.6rem',
                      marginLeft: '0.8rem',
                      cursor: 'pointer',
                    }}
                    onClick={() => setShowCreditLife(true)}
                  />
                </Stack>
                <Typography fontSize={'1.4rem'} fontWeight={700} lineHeight={'2.8rem'} fontStyle={'italic'}>
                  {deathBenefitOptedIn ? (
                    'No additional cost'
                  ) : (
                    <Typography
                      component={'span'}
                      fontSize={'inherit'}
                      fontWeight={'inherit'}
                      lineHeight={'inherit'}
                      fontStyle={'normal'}
                    >
                      R {formatMoney(getQuotationResponse?.insuranceFee ?? 0)}
                    </Typography>
                  )}
                </Typography>
              </FeeRowItem>

              <FeeRowItem sx={{ borderBottom: 'none', paddingTop: '1rem' }}>
                <Typography fontSize={'1.4rem'} fontWeight={700} lineHeight={'2.8rem'} color={theme.palette.primary.main}>
                  Total to repay
                </Typography>
                <Typography fontSize={'1.4rem'} fontWeight={700} lineHeight={'2.8rem'} color={theme.palette.primary.main}>
                  R{' '}
                  {formatMoney(
                    (getQuotationResponse?.totalRepayable ?? 0) + (getQuotationResponse?.deathBenefitContractValue ?? 0)
                  )}
                </Typography>
              </FeeRowItem>
            </Box>
          </>
      }
    </Box>
  );
};

export default QuotationFeeBreakdownPopup;
