export const CONFIGCAT_KEYS = {
    VARIANT: {
        A: 'variant_a',
        B: 'variant_b',
        C: 'variant_c'
    },
    SHOW_BANNER: {
        key: 'showbanner',
        defaultValue: false
    },
    BANNER_TEXT: {
        key: 'bannertext',
        defaultValue: '{}'
    },
    DEATH_BENEFIT_LANDING_PAGE_VARIANT: {
        key: 'deathbenefitlandingpagevariant',
        defaultValue: 'variant_a',
    },
    SHOW_REPAY_EFT_DEPRECATION_BANNER: {
        key: 'showrepayeftdeprecationbanner',
        defaultValue: true
    },
    DEATH_BENEFIT_NAME_VARIANT: {
        key: 'deathbenefitnamevariant',
        defaultValue: { "variant": "a", "name": "Death Benefit" },
    },
};